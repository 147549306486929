<template>
  <div class="pb-20">
    <tabs :tabs="tabs" :tab="tab" v-model="tab" />
    <component :is="tab" />
  </div>
</template>

<script>
export default {
  components: {
    'cash-transactions': () => import(/* webpackChunkName: 'cash-transactions--cash-transactions' */ './CashTransactions'),
    'loanbot-transactions': () => import(/* webpackChunkName: 'cash-transactions-loanbot-transactions' */ './LoanbotTransactions'),
    'awaiting-approval-cash-transactions': () => import(/* webpackChunkName: 'cash-transactions-awaiting-approval' */ './AwaitingApprovalCashTransactions'),
  },
  data() {
    return {
      tab: 'cash-transactions',
    };
  },
  computed: {
    tabs() {
      const tabs = [];
        if (this.canDo('credpal:can_view_cash_transactions')) {
          tabs.push({
            name: 'cash-transactions',
            title: 'Cash Transactions'
          });
      }
        if (this.canDo('credpal:can_view_awaiting_cash_transfers')) {
          tabs.push({
            name: 'awaiting-approval-cash-transactions',
            title: 'Awaiting Approval Cash Transactions'
          });
      } 

        if (this.canDo('credpal:can_view_loanbot_transactions')) {
          tabs.push({
            name: 'loanbot-transactions',
            title: 'Loanbot Transactions'
          });
      }
      return tabs;
    }
  }
};
</script>
